
import HeaderMain from '@/components/shared/HeaderMain.vue';
import { defineComponent, ref, computed } from 'vue';
import { logoTwitter, logoFacebook, logoLinkedin } from 'ionicons/icons';
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonButton,
  IonImg,
  onIonViewWillEnter,
} from '@ionic/vue';
import { Speaker, Agenda, VenueRoom } from '@/types/interface';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import bizvento from '@/services/bizvento';
import { openToast } from '@/helpers/toast';
import { fireBaseTime } from '@/helpers/formatDate';

export default defineComponent({
  name: 'App',
  components: {
    HeaderMain,
    IonContent,
    IonPage,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonIcon,
    IonButton,
    IonImg,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const id = computed(() => route.params.id);
    const isLoading = ref<boolean>(false);
    const readMore = ref<boolean>(false);
    const talks = ref();
    const room = ref();
    const fallbackImage = '../assets/fallback/speaker.png';

    const speaker = computed((): Speaker => {
      return (store.state.speakers.find(
        (speaker: Speaker) => speaker.id === id.value
      ) || {}) as Speaker;
    });

    const agenda = computed(() => {
      return (store.state.agenda as Agenda[]) || [];
    });

    const venueRooms = computed(() => {
      return (store.state.venueRooms as VenueRoom[]) || [];
    });

    const agendaBySpeaker = () => {
      agenda.value.forEach((agd: Agenda) => {
        const agendaSpeakers = agd.speakers;
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (speaker.value.id in agendaSpeakers) {
          talks.value = agd;
          // Get agenda room name
          const foundRoom = venueRooms.value.find(
            (room: VenueRoom) => room.id === talks.value.room_id
          );
          room.value = foundRoom;
        } else {
          return null;
        }
      });
    };
    const isFavourite = computed(() => {
      return store.state.favouriteSpeakers.find(
        (speaker: Speaker) => speaker.id === id.value
      );
    });

    const addToFavourite = async () => {
      isLoading.value = true;
      try {
        await bizvento.user.createFavourite(
          process.env.VUE_APP_EVENT_ID,
          store.state.user.uid,
          String(speaker.value.id),
          'speaker'
        );
        store.commit('SET_FAVOURITE_SPEAKERS', speaker.value);
        console.log('Added to favourites', speaker);
        return openToast(
          'Speaker has been added to favourites list',
          2000,
          'bottom',
          'success'
        );
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const removeFromFavourite = async (): Promise<void> => {
      isLoading.value = true;
      try {
        await bizvento.user.deleteFavourite(
          process.env.VUE_APP_EVENT_ID,
          store.state.user.uid,
          String(speaker.value.id)
        );
        store.commit('REMOVE_FAVOURITE_SPEAKER', speaker.value);
        return openToast(
          'Speaker has been removed from favourites list',
          2000,
          'bottom'
        );
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const handleReadMore = () => {
      readMore.value = !readMore.value;
    };

    onIonViewWillEnter(() => {
      agendaBySpeaker();
    });

    return {
      fireBaseTime,
      addToFavourite,
      removeFromFavourite,
      handleReadMore,
      speaker,
      talks,
      isFavourite,
      room,
      readMore,
      logoTwitter,
      logoFacebook,
      logoLinkedin,
      isLoading,
      fallbackImage,
    };
  },
});
